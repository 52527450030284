export class AppConfig {
    public static readonly COOKIE_NAME = 'v3-portal-user';
    public static readonly META_SOURCE = 'Curtis Portal';

    public static readonly TRAIL_TYPE_CODE_EXTERNAL_FOS = '718abf1a-9356-4541-933d-37bd2d2445d1';
    public static readonly TRAIL_TYPE_CODE_EXTERNAL_APPLICATION_NOTES = '15264f9f-a47b-4233-b19f-216e5a2de670';
    public static readonly TRAIL_TYPE_CODE_EXTERNAL_MANUALS = 'd507b03d-385d-4a10-86c6-b6efda5b79bd';
    public static readonly TRAIL_TYPE_CODE_EXTERNAL_RELEASE_NOTES = '5f5bea1e-5fbf-48bd-ae67-55c3ca4de506';
    public static readonly TRAIL_TYPE_CODE_EXTERNAL_DYNO_PROCESS = '6701ce14-21c8-439d-9f36-d1464f3a50f2';
}

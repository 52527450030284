import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, GridApi, ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SharedWithMeUI.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import Swal from 'sweetalert2';

import SharedWithMeClient from '../lib/SharedWithMeClient';
import { RequestExternalDirectShareList } from '../lib/RequestExternalDirectShareList';
import { ResponseExternalDirectShareList } from '../lib/ResponseExternalDirectShareList';
import { PortalTokenRequest } from '../../../authentication/PortalTokenRequest';
import { ExternalAddCurtisTrailRawRequest } from '../../curtis-trail/lib/request/ExternalAddCurtisTrailRawRequest';
import { AppConfig } from '../../../AppConfig';
import { GenericFileDownloadTrailMetadata } from '../../curtis-trail/lib/request/metadata/GenericFileDownloadTrailMetadata';
import CurtisTrailClient from '../../curtis-trail/lib/CurtisTrailClient';


const SharedWithMeUI: React.FC = () => {
    const id = useSelector((state: AppState) => state.user.id);
    const name = useSelector((state: AppState) => state.user.name);
    const login = useSelector((state: AppState) => state.user.login);
    const email = useSelector((state: AppState) => state.user.email);
    const token = useSelector((state: AppState) => state.user.token);
    const company = useSelector((state: AppState) => state.user.company);
    const ip = useSelector((state: AppState) => state.user.ip);
    const cc = useSelector((state: AppState) => state.user.cc);


    const [sharedFiles, setSharedFiles] = useState<ResponseExternalDirectShareList[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const showAlert = (message: string) => {
        Swal.fire({
            title: 'Attention',
            text: message,
            icon: 'info',
            confirmButtonText: 'Ok',
        });
    };

    const loadSharedFiles = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const tokenRequest = new PortalTokenRequest(token);
            const request = new RequestExternalDirectShareList(tokenRequest, cc);

            console.log('cc', cc);
            const response: ResponseExternalDirectShareList[] = await SharedWithMeClient.listDirectShares(request);

            const adjustedFiles = response.map(item => ({
                ...item,
                externalDownloadLinkUrl: item.externalDownloadLinkUrl
                    ? `/cit1-direct-share/${item.id}/${item.fileName}`
                    : ''
            }));

            setSharedFiles(adjustedFiles);

        } catch (error) {
            console.error('loadSharedFiles', error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        loadSharedFiles();
    }, [token]);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };



    const handleDownloadAndLog = async (params: any) => {
        const currentFile = params.data as ResponseExternalDirectShareList;
        const browserName = navigator.userAgent;
        let browserVersion = "Unknown";
        const language = navigator.language;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const ua = navigator.userAgent;
        const versionMatch = ua.match(/(Firefox|Chrome|Safari|Opera|Edge)\/(\d+)/);
        if (versionMatch && versionMatch.length > 2) {
            browserVersion = `${versionMatch[1]} ${versionMatch[2]}`;
        }

        const metadataObj = new GenericFileDownloadTrailMetadata(
            currentFile.id,
            currentFile.fileName!,
            currentFile.externalDownloadLinkUrl!,
            login,
            company,
            name,
            ip,
            browserName,
            browserVersion,
            language,
            timeZone,
            0, 0, 0, 0, 0, cc, company, AppConfig.META_SOURCE
        );

        const metadata = JSON.stringify(metadataObj);
        const tokenRequest = new PortalTokenRequest(token);
        const request = new ExternalAddCurtisTrailRawRequest(AppConfig.TRAIL_TYPE_CODE_EXTERNAL_FOS, metadata, tokenRequest);

        try {
            await CurtisTrailClient.addCurtisTrailRaw(request);
        } catch (error) {
            console.error('Error logging download:', error);
            showAlert('Failed to log download.');
        }

        // Fix the download URL
        let downloadUrl = currentFile.externalDownloadLinkUrl;
        if (downloadUrl) {
            downloadUrl = downloadUrl.replace('/shared-with-me/', '/cit1-direct-share/');
            window.open(downloadUrl, '_blank');
        } else {
            showAlert('No download link available for this file.');
        }
    };






    const columnDefinition: ColDef[] = [
        {
            headerName: '',
            field: 'externalDownloadLinkUrl',
            width: 50,
            cellRenderer: (params: any) => (
                params.value ? (
                    <div className="download-icon-container">
                        <img
                            src="/icons/download.svg"
                            alt="Download"
                            className="v2-download-grid-icon-button"
                            onClick={() => handleDownloadAndLog(params)}
                        />
                    </div>
                ) : null
            ),
        },
        {
            headerName: 'File Name',
            field: 'fileName',
            flex: 2,
            sortable: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'From',
            field: 'createdByName',
            flex: 1,
            sortable: true,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Date Shared',
            field: 'created',
            flex: 1,
            sortable: true,
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                if (!params.value) return '';
                const date = new Date(params.value);
                const options: Intl.DateTimeFormatOptions = {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                };
                return `${date.toLocaleDateString('en-US', options)} (Pacific Time)`;
            },
        }
    ];

    return (
        <div className="root-form">
            <div className="page-title">
                <img src="/icons/shared-with-me-black.svg" alt="Icon" className="page-icon" />
                Shared With Me
            </div>

            <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
                {sharedFiles.length > 0 ? (
                    <AgGridReact
                        columnDefs={columnDefinition}
                        rowData={sharedFiles}
                        onGridReady={onGridReady}
                        domLayout="autoHeight"
                        pagination={true}
                        paginationPageSize={10}
                        rowSelection="multiple"
                    />
                ) : (
                    <div className="no-files-message">
                        <img src="/icons/exclamation-mark.svg" alt="No files" className="no-files-icon" />
                        <span>No files found.</span>
                    </div>
                )}
            </div>

        </div>
    );
};

export default SharedWithMeUI;
